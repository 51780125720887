import React from 'react';
import { FaTwitter, FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa';
import LazyLoad from 'react-lazyload';
import './Redes.css';
import IG from '../../assets/ig.jpg';
import TW from '../../assets/tw.jpg';
import FB from '../../assets/fb.jpg';

const socialLinks = [
  { name: 'Twitter', icon: <FaTwitter />, url: 'https://x.com/CBManzanares' },
  { name: 'Instagram', icon: <FaInstagram />, url: 'https://www.instagram.com/cbmanzanares/' },
  { name: 'Facebook', icon: <FaFacebook />, url: 'https://www.facebook.com/ClubBaloncestoManzanares/?locale=es_ES' },
];

const imageLinks = [
  { src: IG, alt: 'Instagram', url: 'https://www.instagram.com/cbmanzanares/' },
  { src: TW, alt: 'Twitter', url: 'https://x.com/CBManzanares' },
  { src: FB, alt: 'Facebook', url: 'https://www.facebook.com/ClubBaloncestoManzanares/?locale=es_ES' },
];

const SocialMediaLinks = () => {
  return (
    <div className="social-media-links">
      <div className="username-and-icons">
        <div className="username">@CBMANZANARES</div>
        <div className="icons">
          {socialLinks.map((link, index) => (
            <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
              {link.icon}
            </a>
          ))}
        </div>
      </div>
      <div className="images-row">
        {imageLinks.map((link, index) => (
          <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
            <LazyLoad height={300} offset={100}>
              <img src={link.src} alt={link.alt} />
            </LazyLoad>
          </a>
        ))}
      </div>

      {/**<div className="section-title-yt">ACCEDE A NUESTROS PARTIDOS</div>
      <div className="video-container">
        <iframe 
          src="https://www.youtube.com/embed/6Iza7Ks5zNA" 
          frameBorder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
          title="YouTube video"
        ></iframe>
      </div> */}
      
    </div>
  );
};

export default SocialMediaLinks;
