import React, { useState, useEffect } from 'react';
import './Noticias.css';
import noticia1 from '../../assets/fondonoticias1.jpg';
import noticia2 from '../../assets/fondoplantilla.jpg'; 
import noticia3 from '../../assets/fondonoticias3.jpg';
import noticia4 from '../../assets/fondonoticias4.jpg';


import noticia1Imagen from '../../assets/noticias/noticia13_10.jpg';
import amistosojuvenil from '../../assets/noticias/amistosojuvenil.jpg';

// Componente Modal para mostrar la noticia completa
const Modal = ({ show, onClose, title, date, content }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-noticias">
        <h2>{title}</h2>
        <p className="modal-date">{date}</p> {/* Fecha en el modal */}
        <div dangerouslySetInnerHTML={{ __html: content }} /> {/* Renderizar contenido HTML */}
        <button className="close-button" onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

const Card = ({ id, name = '', date = '', imageUrl, isOpen, handleClick, isMobile, onOpenModal }) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setIsHovered(false);
    }
  }, [isMobile]);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };

  return (
    <div
      className={`card ${isHovered || isOpen ? 'open' : ''}`}
      onClick={() => isMobile && handleClick(id)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ 
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        flex: isHovered || isOpen ? 2 : 1,
        position: 'relative'
      }}
    >
      <div className="card-overlay"></div>
      <div className="card-content">
        <h2 className="card-title">{name}</h2>
        <p className="card-date">{date}</p> {/* Fecha en la carta */}
        <button className="card-button" onClick={() => onOpenModal(id)}>Ver noticia completa</button>
      </div>
    </div>
  );
};

const Gallery = () => {
  const [openCardId, setOpenCardId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: '', date: '', content: '' });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (id) => {
    setOpenCardId(openCardId === id ? null : id);
  };

  // Contenido de las noticias
  const noticias = {
    '1': {
      title: 'Primer partido liga 1º Autonómica',
      date: '12 de octubre de 2024',
      content: `
      <p>El sabado 12 de octubre arrancó la liga 1°División Autonómica.</p>
      <p>Antes del partido el club quiso reconocer la gran trayectoria deportiva de Sonia Molina Prados, antigua jugadora del club que este año alcanzó llegar a los JJOO de Paris. Se le hizo entrega de una bufanda del club y de un polo oficial.</p>
      <p>El primer partido enfrentó a nuestro equipo senior contra Valbrokers CbTomelloso. El partido comenzó a favor de Tomelloso pero después estuvo muy igualado finalizando el partido 63-69.</p>
      <p>Además, el homenaje contó con la presencia de Laura Carrillo,concejala de Deportes y Paqui de la Cruz,concejala de Educación. Para finalizar el homenaje Sonia nos regalo un tiro libre</p>
      <img src="${noticia1Imagen}" alt="Noticia" style="width:100%; margin-top: 20px;" />
      `
    },
    '2': {
      title: 'Crónica amistoso juvenil',
      date: '13 de octubre de 2024',
      content: `
        <p>El domingo 13 de octubre, el equipo juvenil se enfrentó en un partido amistoso al cb valdepeñas. El primer cuarto el equipo vositante se hizo con el dominio de pista pero a partir del segundo cuarto los de Ricky despertaron demostrando su dominio en pista y el partido fue totalmente igualado llegando al fin del mismo en  empate 50-50. Se inicio prologa de 5 min la que finalizó 65-66. </p>
        <img src="${amistosojuvenil}" alt="Noticia" style="width:100%; margin-top: 20px;" />

      `
    },
    '3': {
      title: 'Más noticias próximamente...',
      date: '',
      content: `
        <p>Pronto tendremos más información sobre los próximos eventos y partidos.</p>
        <p>Mantente atento a nuestras actualizaciones para estar al día.</p>
      `
    },
    '4': {
      title: 'Más noticias próximamente...',
      date: '',
      content: `
        <p>Pronto tendremos más información sobre los próximos eventos y partidos.</p>
        <p>Mantente atento a nuestras actualizaciones para estar al día.</p>
      `
    }
  };

  const handleOpenModal = (id) => {
    const { title, date, content } = noticias[id];
    setModalData({ title, date, content });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <section className="gallery-container" id="galeria">
      <Card 
        id="1"
        name="Primer partido liga 1º Autonómica"
        date="12 de octubre de 2024"
        imageUrl={noticia1}
        isOpen={openCardId === '1'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
      <Card 
        id="2"
        name="Crónica amistoso juvenil"
        date="13 de octubre de 2024"
        imageUrl={noticia2}
        isOpen={openCardId === '2'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
      <Card 
        id="3"
        name="Más noticias próximamente..."
        date=""
        imageUrl={noticia3}
        isOpen={openCardId === '3'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
      <Card 
        id="4"
        name="Más noticias próximamente..."
        date=""
        imageUrl={noticia4}
        isOpen={openCardId === '4'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />

      {/* Modal para mostrar la noticia completa */}
      <Modal 
        show={showModal} 
        onClose={handleCloseModal} 
        title={modalData.title} 
        date={modalData.date} 
        content={modalData.content} 
      />
    </section>
  );
};

export default Gallery;
