import React, { useState, useEffect } from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import "./Equipo.css";
import "./EquipoHeader.css";
import logo from "../../assets/logo.jpeg";

const Equipo = ({ teamName, players }) => {
  const [isAtTop, setIsAtTop] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleScroll = () => {
    setIsAtTop(window.scrollY === 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const PlayerCard = ({ image, hoverImage, number, name, position }) => {
    const [currentImage, setCurrentImage] = useState(image);
    const nameParts = name.split(" ");

    const handleMouseEnter = () => {
      setCurrentImage(hoverImage);
    };

    const handleMouseLeave = () => {
      setCurrentImage(image);
    };

    return (
      <div
        className="player-card"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {number && <div className="player-number">{number}</div>}
        <img src={currentImage} alt={name} className="player-image" />
        <div className="player-name">
          {nameParts.map((part, index) => (
            <div key={index} className="name-part">
              {part}
            </div>
          ))}
        </div>
        <div className="player-position">{position}</div>
      </div>
    );
  };

  const technicalStaff = players.filter(
    (player) => player.position === "CUERPO TÉCNICO"
  );
  const otherPlayers = players.filter(
    (player) => player.position !== "CUERPO TÉCNICO"
  );

  return (
    <div className="equipo-container" style={{ marginTop: "120px" }}>
      <div className="equipo-background-image"></div>
      <header className={`equipo-header ${isAtTop ? "at-top" : "scrolled"}`}>
        <div className="header-left">
          <div className="header-logo" onClick={() => navigate("/")}>
            <img
              src={logo}
              alt="Team Logo"
              className={`${isAtTop ? "large-logo" : ""}`}
            />
          </div>
        </div>
        <div className="header-center">
          <div
            className={`header-section ${
              location.pathname === "/" ? "active" : ""
            }`}
            onClick={() => navigate("/")}
          >
            Inicio
          </div>
          <div
            className={`header-section ${
              location.pathname === "/teamA" ? "active" : ""
            }`}
            onClick={() => navigate("/teamA")}
          >
            Senior
          </div>
          <div
            className={`header-section ${
              location.pathname === "/teamB" ? "active" : ""
            }`}
            onClick={() => navigate("/teamB")}
          >
            Junior Masculino
          </div>
        </div>
        <div className="header-socials">
          <a
            href="https://www.facebook.com/ClubBaloncestoManzanares/?locale=es_ES"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook size={30} />
          </a>
          <a
            href="https://x.com/CBManzanares"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter size={30} />
          </a>
          <a
            href="https://www.instagram.com/cbmanzanares/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={30} />
          </a>
        </div>
      </header>
      <div className="team-roster">
        {otherPlayers.map((player, index) => (
          <PlayerCard
          key={`${player.number}-${player.name}`}  // Usa el número como parte del key
          image={player.image}
          hoverImage={player.hoverImage}
          number={player.number}  // Ahora el número se toma correctamente de los datos del jugador
          name={player.name}
          position={player.position}
        />
        ))}
      </div>

      {technicalStaff.length > 0 && (
        <div className="technical-staff-section">
          <h2>CUERPO TÉCNICO</h2>
          <div className="team-roster">
            {technicalStaff.map((player, index) => (
              <PlayerCard
                key={`${player.name}-${index}`}
                image={player.image}
                hoverImage={player.hoverImage}
                name={player.name}
                position={player.position}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Equipo;